@charset "UTF-8";
@font-face {
  font-family: "Manrope";
  src: url("manrope-extrabold.woff2") format("woff2"),
    /* Modern browsers */ url("manrope-extrabold.woff") format("woff"),
    /* Legacy browsers */ url("manrope-extrabold.otf") format("truetype"); /* Android, iOS */
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Manrope";
  src: url("manrope-bold.woff2") format("woff2"),
    url("manrope-bold.woff") format("woff"),
    url("manrope-bold.otf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Manrope";
  src: url("manrope-semibold.woff2") format("woff2"),
    url("manrope-semibold.woff") format("woff"),
    url("manrope-semibold.otf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Manrope";
  src: url("manrope-medium.woff2") format("woff2"),
    url("manrope-medium.woff") format("woff"),
    url("manrope-medium.otf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Manrope";
  src: url("manrope-regular.woff2") format("woff2"),
    url("manrope-regular.woff") format("woff"),
    url("manrope-regular.otf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Manrope";
  src: url("manrope-light.woff2") format("woff2"),
    url("manrope-light.woff") format("woff"),
    url("manrope-light.otf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Manrope";
  src: url("manrope-thin.woff2") format("woff2"),
    url("manrope-thin.woff") format("woff"),
    url("manrope-thin.otf") format("truetype");
  font-style: normal;
  font-weight: 200;
}
body,
html {
  font-family: "Manrope", sans-serif;
}
*,
*:before,
*:after {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
