.docs-dark {
  color: #fff;
}

.docs-light {
  color: rgba(0, 0, 0, 0.87);
}

main[data-theme="dark"] .permalinkContainer {
  border: rgba(255, 255, 255, 0.3) 1px solid;
  border-radius: 4px;
  float: left;
}

main[data-theme="dark"] .permalinkContainer > span {
  margin: 4px 8px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
}

main[data-theme="dark"] .permalinkContainer > span > .linkIcon {
  margin-left: 2px;
  padding-right: 8px;
  margin-right: 8px;
  box-sizing: content-box;
  border-right: rgba(255, 255, 255, 0.3) 1px solid;
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
}

main[data-theme="dark"] .permalinkContainer > span > .copyButton {
  margin-right: 2px;
  padding-left: 8px;
  margin-left: 8px;
  box-sizing: content-box;
  border-left: rgba(255, 255, 255, 0.3) 1px solid;
  color: rgba(255, 255, 255, 1);
  display: inline-block;
  cursor: pointer;
  transition: color cubic-bezier(0.075, 0.82, 0.165, 1) 250ms;
}

main[data-theme="dark"] .permalinkContainer > span > .copyButton:hover {
  color: rgba(255, 255, 255, 0.9);
}

main[data-theme="light"] .permalinkContainer {
  border: rgba(0, 0, 0, 0.3) 1px solid;
  border-radius: 4px;
  float: left;
}

main[data-theme="light"] .permalinkContainer > span {
  margin: 4px 8px;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
}

main[data-theme="light"] .permalinkContainer > span > .linkIcon {
  margin-left: 2px;
  padding-right: 8px;
  margin-right: 8px;
  box-sizing: content-box;
  border-right: rgba(0, 0, 0, 0.3) 1px solid;
  color: rgba(0, 0, 0, 0.7);
  display: inline-block;
}

main[data-theme="light"] .permalinkContainer > span > .copyButton {
  margin-right: 2px;
  padding-left: 8px;
  margin-left: 8px;
  box-sizing: content-box;
  border-left: rgba(0, 0, 0, 0.3) 1px solid;
  color: rgba(0, 0, 0, 0.7);
  display: inline-block;
  cursor: pointer;
  transition: color cubic-bezier(0.075, 0.82, 0.165, 1) 250ms;
}

main[data-theme="light"] .permalinkContainer > span > .copyButton:hover {
  color: rgba(0, 0, 0, 1);
}

a.doc-anchor-link {
  display: none;
}

.md-header-container:hover a.doc-anchor-link {
  display: inline-block;
  margin-left: 8px;
  text-decoration: none;
}

.md-header-container *:not(a) {
  float: left;
}

.md-header-container::after {
  clear: both;
  content: "";
  display: table;
}
