@import url(https://fonts.googleapis.com/css?family=Volkhov:400,700);
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main[data-theme="dark"] a:not([role="button"]):not([role="menuitem"]) {
    color: #64ffda; /* Secondary colour from MUI theme adjusted to suitable contrast ratio */
    -webkit-transition: color 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 100ms cubic-bezier(0.4, 0, 0.2, 1);
}

main[data-theme="dark"] a:hover:not([role="button"]):not([role="menuitem"]) {
    color: #1de9b6;
}

main[data-theme="light"] a:not([role="button"]):not([role="menuitem"]) {
    color: #00583e; /* Secondary colour from MUI theme adjusted to suitable contrast ratio */
    -webkit-transition: color 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 100ms cubic-bezier(0.4, 0, 0.2, 1);
}

main[data-theme="light"] a:hover:not([role="button"]):not([role="menuitem"]) {
    color: #2b2b2b;
}

.linkContainer a.externalLink:link,
.linkContainer a.externalLink:visited {
    color: inherit !important;
}

.linkContainer,
.linkContainer a.externalLink,
.linkContainer svg.mdi-icon path {
    color: #03a9f4 !important;
}

.linkContainer:hover,
.linkContainer:hover a.externalLink,
.linkContainer:hover svg.mdi-icon path {
    color: #00bfa6 !important;
}

footer a:link,
footer a:visited {
    color: inherit;
    margin: 8px;
}

@charset "UTF-8";
@font-face {
  font-family: "Manrope";
  src: url(/static/media/manrope-extrabold.7af88092.woff2) format("woff2"),
     url(/static/media/manrope-extrabold.5f25f98a.woff) format("woff"),
     url(/static/media/manrope-extrabold.6b7897c9.otf) format("truetype"); /* Android, iOS */
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Manrope";
  src: url(/static/media/manrope-bold.a6fa3bd1.woff2) format("woff2"),
    url(/static/media/manrope-bold.c246dc6a.woff) format("woff"),
    url(/static/media/manrope-bold.cb519ea7.otf) format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Manrope";
  src: url(/static/media/manrope-semibold.a00289fe.woff2) format("woff2"),
    url(/static/media/manrope-semibold.8325e7ea.woff) format("woff"),
    url(/static/media/manrope-semibold.ae7c5af0.otf) format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Manrope";
  src: url(/static/media/manrope-medium.441244cf.woff2) format("woff2"),
    url(/static/media/manrope-medium.16a2e274.woff) format("woff"),
    url(/static/media/manrope-medium.31973fa5.otf) format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Manrope";
  src: url(/static/media/manrope-regular.3c100c61.woff2) format("woff2"),
    url(/static/media/manrope-regular.3cea6e29.woff) format("woff"),
    url(/static/media/manrope-regular.b0a4a6cc.otf) format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Manrope";
  src: url(/static/media/manrope-light.37c00b97.woff2) format("woff2"),
    url(/static/media/manrope-light.860d372f.woff) format("woff"),
    url(/static/media/manrope-light.85b96796.otf) format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Manrope";
  src: url(/static/media/manrope-thin.6c9f93a2.woff2) format("woff2"),
    url(/static/media/manrope-thin.484b6c5b.woff) format("woff"),
    url(/static/media/manrope-thin.ab2fd20e.otf) format("truetype");
  font-style: normal;
  font-weight: 200;
}
body,
html {
  font-family: "Manrope", sans-serif;
}
*,
*:before,
*:after {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.docs-dark {
  color: #fff;
}

.docs-light {
  color: rgba(0, 0, 0, 0.87);
}

main[data-theme="dark"] .permalinkContainer {
  border: rgba(255, 255, 255, 0.3) 1px solid;
  border-radius: 4px;
  float: left;
}

main[data-theme="dark"] .permalinkContainer > span {
  margin: 4px 8px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
}

main[data-theme="dark"] .permalinkContainer > span > .linkIcon {
  margin-left: 2px;
  padding-right: 8px;
  margin-right: 8px;
  box-sizing: content-box;
  border-right: rgba(255, 255, 255, 0.3) 1px solid;
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
}

main[data-theme="dark"] .permalinkContainer > span > .copyButton {
  margin-right: 2px;
  padding-left: 8px;
  margin-left: 8px;
  box-sizing: content-box;
  border-left: rgba(255, 255, 255, 0.3) 1px solid;
  color: rgba(255, 255, 255, 1);
  display: inline-block;
  cursor: pointer;
  -webkit-transition: color cubic-bezier(0.075, 0.82, 0.165, 1) 250ms;
  transition: color cubic-bezier(0.075, 0.82, 0.165, 1) 250ms;
}

main[data-theme="dark"] .permalinkContainer > span > .copyButton:hover {
  color: rgba(255, 255, 255, 0.9);
}

main[data-theme="light"] .permalinkContainer {
  border: rgba(0, 0, 0, 0.3) 1px solid;
  border-radius: 4px;
  float: left;
}

main[data-theme="light"] .permalinkContainer > span {
  margin: 4px 8px;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
}

main[data-theme="light"] .permalinkContainer > span > .linkIcon {
  margin-left: 2px;
  padding-right: 8px;
  margin-right: 8px;
  box-sizing: content-box;
  border-right: rgba(0, 0, 0, 0.3) 1px solid;
  color: rgba(0, 0, 0, 0.7);
  display: inline-block;
}

main[data-theme="light"] .permalinkContainer > span > .copyButton {
  margin-right: 2px;
  padding-left: 8px;
  margin-left: 8px;
  box-sizing: content-box;
  border-left: rgba(0, 0, 0, 0.3) 1px solid;
  color: rgba(0, 0, 0, 0.7);
  display: inline-block;
  cursor: pointer;
  -webkit-transition: color cubic-bezier(0.075, 0.82, 0.165, 1) 250ms;
  transition: color cubic-bezier(0.075, 0.82, 0.165, 1) 250ms;
}

main[data-theme="light"] .permalinkContainer > span > .copyButton:hover {
  color: rgba(0, 0, 0, 1);
}

a.doc-anchor-link {
  display: none;
}

.md-header-container:hover a.doc-anchor-link {
  display: inline-block;
  margin-left: 8px;
  text-decoration: none;
}

.md-header-container *:not(a) {
  float: left;
}

.md-header-container::after {
  clear: both;
  content: "";
  display: table;
}

#privacyAndCookiePolicyRoot .firstList {
  counter-reset: chapter;
}
#privacyAndCookiePolicyRoot ol.clauses,
#privacyAndCookiePolicyRoot ol.parties,
#privacyAndCookiePolicyRoot ol.recitals {
  margin-left: 0;
  padding: 0 0 0 2em;
}
#privacyAndCookiePolicyRoot ol.clauses ol,
#privacyAndCookiePolicyRoot ol.parties ol,
#privacyAndCookiePolicyRoot ol.recitals ol {
  margin-left: 0;
  padding: 0 0 0 2em;
}
#privacyAndCookiePolicyRoot ol.clauses li,
#privacyAndCookiePolicyRoot ol.parties li,
#privacyAndCookiePolicyRoot ol.recitals li {
  font-size: 15px !important;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
#privacyAndCookiePolicyRoot ol.clauses > li > ol > li {
  list-style: lower-alpha !important;
}
#privacyAndCookiePolicyRoot ol.clauses > li > ol > li > ol > li {
  list-style: lower-roman;
}
#privacyAndCookiePolicyRoot ol.clauses > li,
#privacyAndCookiePolicyRoot ol.parties > li {
  list-style-type: none;
  counter-increment: chapter;
}
#privacyAndCookiePolicyRoot ol.parties > li:before,
#privacyAndCookiePolicyRoot ol.clauses > li:before {
  width: 2em;
  margin-left: -2em;
  display: inline-block;
}
#privacyAndCookiePolicyRoot ol.clauses > li:before {
  content: counter(chapter) ".    ";
}
#privacyAndCookiePolicyRoot ol.parties > li:before {
  content: "(" counter(chapter) ") ";
}
#privacyAndCookiePolicyRoot ol.recitals {
  list-style: upper-alpha !important;
}
#privacyAndCookiePolicyRoot ol.clausesNested,
#privacyAndCookiePolicyRoot ol.clausesNested ol {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
}
#privacyAndCookiePolicyRoot ol.clausesNested {
  padding: 0 5em 0 5em;
}
#privacyAndCookiePolicyRoot ol.clausesNested li {
  font-weight: bold;
}
#privacyAndCookiePolicyRoot ol.clausesNested li p,
#privacyAndCookiePolicyRoot ol.clausesNested li li {
  font-weight: normal;
}
#privacyAndCookiePolicyRoot ol.clausesNested li ol > li {
  margin-top: 1em;
  margin-bottom: 1em;
}
#privacyAndCookiePolicyRoot ol.clausesNested li p {
  margin-top: 0;
}
#privacyAndCookiePolicyRoot ol.clausesNested > li,
#privacyAndCookiePolicyRoot ol.clausesNested ol > li {
  display: block;
  position: relative;
}
#privacyAndCookiePolicyRoot ol.clausesNested > li:before,
#privacyAndCookiePolicyRoot ol.clausesNested ol > li:before {
  display: inline-block;
  content: counters(item, ".") ". ";
  counter-increment: item;
  width: 4em;
  margin-left: -4em;
}
#privacyAndCookiePolicyRoot {
  font-family: "Volkhov", sans-serif !important;
  font-size: 15px !important;
  margin: 2em 4em 4em 4em !important;
  padding: 2rem;
  background-color: #eee;
}
#privacyAndCookiePolicyRoot h1 {
  font-family: "Volkhov", sans-serif !important;
  font-size: x-large !important;
  margin-top: 1em !important;
  margin-bottom: 1.5em !important;
  text-align: center !important;
  font-weight: bold !important;
}
#privacyAndCookiePolicyRoot h2 {
  font-size: 15px !important;
  font-family: "Volkhov", sans-serif !important;
  margin-top: 2em !important;
  font-weight: bold !important;
}
#privacyAndCookiePolicyRoot h3 {
  font-weight: bold;
  font-size: 15px;
  margin-top: 2em;
}
#privacyAndCookiePolicyRoot table.definitions,
#privacyAndCookiePolicyRoot div.schedule table {
  border-collapse: collapse !important;
  margin: 1em 0 2em -2em !important;
}
#privacyAndCookiePolicyRoot table.definitions tr:nth-child(odd),
#privacyAndCookiePolicyRoot div.schedule table tr:nth-child(odd) {
  background-color: #ccc;
}
#privacyAndCookiePolicyRoot table.definitions td,
#privacyAndCookiePolicyRoot table.definitions th,
#privacyAndCookiePolicyRoot div.schedule table td,
#privacyAndCookiePolicyRoot div.schedule table th {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  padding: 5px !important;
  vertical-align: top !important;
  text-align: left !important;
}
#privacyAndCookiePolicyRoot table.definitions th,
#privacyAndCookiePolicyRoot div.schedule table th {
  width: 25% !important;
}
#privacyAndCookiePolicyRoot table.signature,
#privacyAndCookiePolicyRoot table.signature2,
#privacyAndCookiePolicyRoot div.signature table {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
#privacyAndCookiePolicyRoot table.signature th,
#privacyAndCookiePolicyRoot table.signature td,
#privacyAndCookiePolicyRoot table.signature2 th,
#privacyAndCookiePolicyRoot table.signature2 td,
#privacyAndCookiePolicyRoot div.signature table th,
#privacyAndCookiePolicyRoot div.signature table td {
  padding: 1em 0 0 0 !important;
}
#privacyAndCookiePolicyRoot table.signature th,
#privacyAndCookiePolicyRoot table.signature2 th,
#privacyAndCookiePolicyRoot div.signature table th {
  font-weight: normal !important;
  text-align: right !important;
  padding-right: 1em !important;
  min-width: 90px;
}
#privacyAndCookiePolicyRoot table.signature td.signatureBox,
#privacyAndCookiePolicyRoot table.signature2 td.signatureBox,
#privacyAndCookiePolicyRoot div.signature table td.signatureBox {
  border-bottom: 1px solid black !important;
  min-width: 300px;
}
#privacyAndCookiePolicyRoot .witness table th,
#privacyAndCookiePolicyRoot .witness table td {
  border: none;
  vertical-align: text-top;
}
#privacyAndCookiePolicyRoot .witness table th {
  font-weight: normal;
}
#privacyAndCookiePolicyRoot ol.letterList {
  list-style: lower-alpha !important;
}
#privacyAndCookiePolicyRoot div.schedule {
  border-top: 1px solid black;
}
#privacyAndCookiePolicyRoot div.schedule h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1em;
}
#privacyAndCookiePolicyRoot div.schedule h3 {
  margin-top: 1em;
  text-align: center;
  margin-bottom: 3em;
}
#privacyAndCookiePolicyRoot table.withBorders {
  width: 100%;
  border-width: 0px;
  border-spacing: 0px;
  border-style: none;
  border-color: gray;
  border-collapse: collapse;
  background-color: white;
}
#privacyAndCookiePolicyRoot table.withBorders th {
  border-width: 1px;
  padding: 5px;
  border-style: solid;
  border-color: black;
  background-color: white;
}
#privacyAndCookiePolicyRoot table.withBorders td {
  border-width: 1px;
  padding: 5px;
  border-style: solid;
  border-color: black;
  background-color: white;
}
#privacyAndCookiePolicyRoot .pagebreakhere {
  page-break-before: always;
}

@-webkit-keyframes WAVE {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(-170deg);
            transform: rotate(-170deg);
  }
  38% {
    -webkit-transform: rotate(-150deg);
            transform: rotate(-150deg);
  }
  46% {
    -webkit-transform: rotate(-190deg);
            transform: rotate(-190deg);
  }
  54% {
    -webkit-transform: rotate(-150deg);
            transform: rotate(-150deg);
  }
  62% {
    -webkit-transform: rotate(-190deg);
            transform: rotate(-190deg);
  }
  68% {
    -webkit-transform: rotate(-190deg);
            transform: rotate(-190deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes WAVE {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(-170deg);
            transform: rotate(-170deg);
  }
  38% {
    -webkit-transform: rotate(-150deg);
            transform: rotate(-150deg);
  }
  46% {
    -webkit-transform: rotate(-190deg);
            transform: rotate(-190deg);
  }
  54% {
    -webkit-transform: rotate(-150deg);
            transform: rotate(-150deg);
  }
  62% {
    -webkit-transform: rotate(-190deg);
            transform: rotate(-190deg);
  }
  68% {
    -webkit-transform: rotate(-190deg);
            transform: rotate(-190deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes SMILEEYES {
  0% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  30% {
    -webkit-transform: translateY(-23px);
            transform: translateY(-23px);
  }
  70% {
    -webkit-transform: translateY(-23px);
            transform: translateY(-23px);
  }
  75% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  80% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  85% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

@keyframes SMILEEYES {
  0% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  30% {
    -webkit-transform: translateY(-23px);
            transform: translateY(-23px);
  }
  70% {
    -webkit-transform: translateY(-23px);
            transform: translateY(-23px);
  }
  75% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  80% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  85% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

#errorSection:hover ~ #androidContainer,
#androidContainer:hover {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  opacity: 1;

  /* transition: transform 200ms cubic-bezier(0.4, 0, 1, 1); */
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition-delay: 4.5s;
          transition-delay: 4.5s;
}

#androidContainer {
  -webkit-transform-origin: 25% 90%;
          transform-origin: 25% 90%;
  top: 0;
  right: 0;
  z-index: -1;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: block;
  position: absolute;
  margin-right: -50px;
  margin-top: -75px;

  opacity: 0;

  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 200ms cubic-bezier(0.4, 0, 1, 1);

  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 200ms cubic-bezier(0.4, 0, 1, 1);

  transition: transform 200ms cubic-bezier(0.4, 0, 1, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);

  transition: transform 200ms cubic-bezier(0.4, 0, 1, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 200ms cubic-bezier(0.4, 0, 1, 1);
}

#errorSection:hover ~ #androidContainer #android #body .shape.shape.b2,
#androidContainer:hover #android #body .shape.shape.b2 {
  -webkit-transform-origin: center 7%;
          transform-origin: center 7%;
  -webkit-animation: WAVE 2s 6s infinite;
          animation: WAVE 2s 6s infinite;

  /* transition: transform 200ms cubic-bezier(0.4, 0, 1, 1); */
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
  transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

#errorSection:hover ~ #androidContainer #android #head .eyes::after,
#androidContainer:hover #android #head .eyes::after {
  -webkit-animation: SMILEEYES 2s 6s infinite;
          animation: SMILEEYES 2s 6s infinite;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

#android {
  width: 300px;
  height: 300px;
  margin: 50px auto;
  position: relative;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
#head {
  border-radius: 65px 65px 0 0;
  width: 130px;
  height: 65px;
  margin: 0 auto;
  background-color: #a4ca39;
}
.antenna {
  width: 4px;
  height: 30px;
  position: absolute;
  top: -12px;
  border-radius: 5px;
  background-color: #a4ca39;
}
.antenna.a1 {
  left: 36%;
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.antenna.a2 {
  left: 61%;
  -webkit-transform: rotate(33deg);
          transform: rotate(33deg);
}
.eyes {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 29px;
  border-radius: 50%;
  background: #fff;
}
.eyes.a1 {
  left: 38%;
}
.eyes.a2 {
  left: 58%;
}

.eyes::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 29px;
  border-radius: 50%;
  background: #a4ca39;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

#body {
  border-radius: 0 0 15px 15px;
  width: 130px;
  height: 112px;
  margin: 4px auto;
  background-color: #a4ca39;
  position: relative;
}
.shape {
  width: 30px;
  height: 90px;
  border-radius: 30px;
  background-color: #a4ca39;
  position: absolute;
  top: -3px;
}
.shape.b1 {
  left: -37px;
}
.shape.b2 {
  right: -36px;
}
.shape.b3 {
  top: 63%;
  left: 25px;
}
.shape.b4 {
  top: 63%;
  right: 25px;
}
.clearfix::after {
  clear: "both";
  content: "";
  display: "table";
}

