body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main[data-theme="dark"] a:not([role="button"]):not([role="menuitem"]) {
    color: #64ffda; /* Secondary colour from MUI theme adjusted to suitable contrast ratio */
    transition: color 100ms cubic-bezier(0.4, 0, 0.2, 1);
}

main[data-theme="dark"] a:hover:not([role="button"]):not([role="menuitem"]) {
    color: #1de9b6;
}

main[data-theme="light"] a:not([role="button"]):not([role="menuitem"]) {
    color: #00583e; /* Secondary colour from MUI theme adjusted to suitable contrast ratio */
    transition: color 100ms cubic-bezier(0.4, 0, 0.2, 1);
}

main[data-theme="light"] a:hover:not([role="button"]):not([role="menuitem"]) {
    color: #2b2b2b;
}

.linkContainer a.externalLink:link,
.linkContainer a.externalLink:visited {
    color: inherit !important;
}

.linkContainer,
.linkContainer a.externalLink,
.linkContainer svg.mdi-icon path {
    color: #03a9f4 !important;
}

.linkContainer:hover,
.linkContainer:hover a.externalLink,
.linkContainer:hover svg.mdi-icon path {
    color: #00bfa6 !important;
}

footer a:link,
footer a:visited {
    color: inherit;
    margin: 8px;
}
